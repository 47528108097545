/* eslint-disable no-unused-vars */
import axios from "axios";
import mixins from "@/mixins";

import { GET_REQUEST, POPULATE_LIST } from "@/store/actions";

import {
  POPULATE_LIST_SUCCESS,
  SET_POPULATING_LIST_VALUE
} from "@/store/mutations";

const state = {
  list: [],
  populatingList: false
};

const getters = {
  isSet: state => {
    return state.list.length > 0;
  },
  byId: state => {
    return mixins.methods.convertToObjectById(state.list);
  }
};

const actions = {
  [POPULATE_LIST]: ({ commit, dispatch }) => {
    commit(SET_POPULATING_LIST_VALUE, true);
    return new Promise((resolve, reject) => {
      dispatch(GET_REQUEST, {})
        .then(data => {
          commit(POPULATE_LIST_SUCCESS, data);
          commit(SET_POPULATING_LIST_VALUE, false);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_REQUEST]: ({ commit }, { params = {} }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: "https://api-v2.jumpfeed.com/internal/split-parameters",
        params
      })
        .then(resp => {
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

const mutations = {
  [POPULATE_LIST_SUCCESS]: (state, data) => {
    state.list = data;
  },
  [SET_POPULATING_LIST_VALUE]: (state, value) => {
    state.populatingList = !!value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
