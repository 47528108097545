import { createStore } from "vuex";
import auth from "@/store/modules/auth";
import user from "@/store/modules/user";
import offers from "@/store/modules/offers";
import brands from "@/store/modules/brands";
import channels from "@/store/modules/channels";
import businesses from "@/store/modules/businesses";
import events from "@/store/modules/events";
import verticals from "@/store/modules/verticals";
import mixins from "@/mixins";

export default createStore({
  state: {
    productName: "Jumpfeed Dashboard",
    leftDrawerOpen: !mixins.methods.isMobileUserAgent()
  },
  mutations: {
    toggleLeftDrawer(state) {
      state.leftDrawerOpen = !state.leftDrawerOpen;
    }
  },
  actions: {
    reset({ commit }) {
      commit("user/RESET");
    }
  },
  modules: {
    auth,
    user,
    offers,
    brands,
    channels,
    businesses,
    events,
    verticals
  }
});
