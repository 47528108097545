/* Actions */
export const GET_REQUEST = "GET_REQUEST";
export const POST_REQUEST = "POST_REQUEST";
export const POPULATE_LIST = "POPULATE_LIST";

export const GET_ACCOUNTS_REQUEST = "GET_ACCOUNTS_REQUEST";
export const POST_ACCOUNTS_REQUEST = "POST_ACCOUNTS_REQUEST";
export const POPULATE_ACCOUNTS_LIST = "POPULATE_ACCOUNTS_LIST";

/* Mutations */
export const POPULATE_LIST_SUCCESS = "POPULATE_LIST_SUCCESS";
export const SET_POPULATING_LIST_VALUE = "SET_POPULATING_LIST_VALUE";

export const POPULATE_ACCOUNTS_LIST_SUCCESS = "POPULATE_ACCOUNTS_LIST_SUCCESS";
export const SET_POPULATING_ACCOUNTS_LIST_VALUE =
  "SET_POPULATING_ACCOUNTS_LIST_VALUE";
