/* eslint-disable no-unused-vars */
import axios from "axios";

import {
  GET_REQUEST,
  GET_ACCOUNTS_REQUEST,
  POST_REQUEST,
  POST_ACCOUNTS_REQUEST,
  POPULATE_LIST,
  POPULATE_ACCOUNTS_LIST,
  POPULATE_LIST_SUCCESS,
  POPULATE_ACCOUNTS_LIST_SUCCESS,
  SET_POPULATING_LIST_VALUE,
  SET_POPULATING_ACCOUNTS_LIST_VALUE
} from "@/store/actions/businesses";

const state = {
  list: [],
  populatingList: false,
  accounts: {
    list: [],
    populatingList: false
  }
};

const getters = {
  isSet: state => {
    return state.list.length > 0;
  },
  isAccountsSet: state => {
    return state.accounts.list.length > 0;
  }
};

const actions = {
  [POPULATE_LIST]: ({ commit, dispatch }) => {
    commit(SET_POPULATING_LIST_VALUE, true);
    return new Promise((resolve, reject) => {
      dispatch(GET_REQUEST, {})
        .then(data => {
          commit(POPULATE_LIST_SUCCESS, data);
          commit(SET_POPULATING_LIST_VALUE, false);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_REQUEST]: ({ commit }, { params = {} }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: "https://api.jumpfeed.com/businesses",
        params
      })
        .then(resp => {
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  [POST_REQUEST]: ({ commit, dispatch }, { endpoint, params = {} }) => {
    return new Promise((resolve, reject) => {
      axios
        .post("https://api.jumpfeed.com/businesses/" + endpoint, params)
        .then(resp => {
          dispatch(POPULATE_LIST);
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  [POPULATE_ACCOUNTS_LIST]: ({ commit, dispatch }) => {
    commit(SET_POPULATING_ACCOUNTS_LIST_VALUE, true);
    return new Promise((resolve, reject) => {
      dispatch(GET_ACCOUNTS_REQUEST, {})
        .then(data => {
          commit(POPULATE_ACCOUNTS_LIST_SUCCESS, data);
          commit(SET_POPULATING_ACCOUNTS_LIST_VALUE, false);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_ACCOUNTS_REQUEST]: ({ commit }, { params = {} }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: "https://api.jumpfeed.com/businesses/accounts",
        params
      })
        .then(resp => {
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  [POST_ACCOUNTS_REQUEST]: (
    { commit, dispatch },
    { endpoint, params = {} }
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "https://api.jumpfeed.com/businesses/accounts/" + endpoint,
          params
        )
        .then(resp => {
          dispatch(POPULATE_ACCOUNTS_LIST);
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

const mutations = {
  [POPULATE_LIST_SUCCESS]: (state, data) => {
    state.list = data;
  },
  [SET_POPULATING_LIST_VALUE]: (state, value) => {
    state.populatingList = !!value;
  },
  [POPULATE_ACCOUNTS_LIST_SUCCESS]: (state, data) => {
    state.accounts.list = data;
  },
  [SET_POPULATING_ACCOUNTS_LIST_VALUE]: (state, value) => {
    state.accounts.populatingList = !!value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
