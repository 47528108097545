/* eslint-disable no-unused-vars */
import axios from "axios";

import {
  GET_TYPES_REQUEST,
  POPULATE_TYPES_LIST,
  POPULATE_TYPES_LIST_SUCCESS,
  SET_POPULATING_TYPES_LIST_VALUE
} from "@/store/actions/events";
import mixins from "@/mixins";

const state = {
  list: [],
  populatingList: false,
  types: {
    list: [],
    populatingList: false
  }
};

const getters = {
  isSet: state => {
    return state.list.length > 0;
  },
  isTypesSet: state => {
    return state.types.list.length > 0;
  },
  typesById: state => {
    return mixins.methods.convertToObjectById(state.types.list);
  }
};

const actions = {
  [POPULATE_TYPES_LIST]: ({ commit, dispatch }) => {
    commit(SET_POPULATING_TYPES_LIST_VALUE, true);
    return new Promise((resolve, reject) => {
      dispatch(GET_TYPES_REQUEST, {})
        .then(data => {
          commit(POPULATE_TYPES_LIST_SUCCESS, data);
          commit(SET_POPULATING_TYPES_LIST_VALUE, false);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_TYPES_REQUEST]: ({ commit }, { params = {} }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: "https://api.jumpfeed.com/events/types",
        params
      })
        .then(resp => {
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

const mutations = {
  [POPULATE_TYPES_LIST_SUCCESS]: (state, data) => {
    state.types.list = data;
  },
  [SET_POPULATING_TYPES_LIST_VALUE]: (state, value) => {
    state.types.populatingList = !!value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
