<template>
  <router-view style="min-width: 1024px" />
</template>

<script>
//import { ref } from 'vue'
export default {};
</script>

<style lang="scss">
#app {
  background: color(background);
}
</style>
