import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';;
import quasarUserOptions from "./quasar-user-options";
import mixins from "./mixins";

const app = createApp(App);
app.use(Quasar, quasarUserOptions);
app.use(store);
app.use(router);
app.mixin(mixins);

app.directive("hover-editable", {
  beforeMount(el) {
    let icon = null;

    el.style.cursor = "pointer";
    el.style.position = "relative";

    let hoverFunction = () => {
      //el.classList.add('--editable-hover');
      icon = document.createElement("span");
      icon.className = "material-icons";
      icon.innerText = "edit";
      icon.style.position = "absolute";
      icon.style.top = "50%";
      icon.style.transform = "translateY(-50%)";
      icon.style.right = "5%";
      icon.style.color = "#616161";
      el.append(icon);
    };
    let unhoverFunction = () => {
      //el.classList.remove('--editable-hover');
      el.removeChild(icon);
    };
    el.addEventListener("mouseover", hoverFunction);
    el.addEventListener("mouseout", unhoverFunction);
  }
});

app.mount("#app");
app.config.globalProperties.$_ = require("lodash");
