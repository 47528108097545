/* eslint-disable no-unused-vars */
import axios from "axios";
import mixins from "@/mixins";

import {
  GET_REQUEST,
  POPULATE_LIST,
  POPULATE_LIST_SUCCESS
} from "@/store/actions/channels";

const state = {
  list: []
};

const getters = {
  isSet: state => {
    return state.list.length > 0;
  },
  byId: state => {
    return mixins.methods.convertToObjectById(state.list);
  }
};

const actions = {
  [POPULATE_LIST]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      dispatch(GET_REQUEST, {})
        .then(data => {
          commit(POPULATE_LIST_SUCCESS, data);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_REQUEST]: ({ commit }, { params = {} }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: "https://api.jumpfeed.com/channels",
        params
      })
        .then(resp => {
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

const mutations = {
  [POPULATE_LIST_SUCCESS]: (state, data) => {
    state.list = data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
