import { createRouter, createWebHistory } from "vue-router";
import store from "@/store";
import axios from "axios";

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login")
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter: (to, from, next) => {
      store.dispatch("auth/LOGOUT").then(() => {
        store.dispatch("reset");
        next("/login");
      });
    }
  },
  {
    path: "/external-reporting",
    name: "externalReporting",
    component: () => import("@/views/Reporting/ExternalNetworkPerformance")
  },
  {
    path: "/",
    name: "",
    redirect: "/reporting/network-performance",
    component: () => import("@/components/Skeletons/Dashboard"),
    children: [
      {
        path: "/:pathMatch(.*)*",
        name: "404",
        component: () => import("@/views/ComingSoon.vue")
      },
      {
        path: "/reporting/network-performance",
        name: "networkPerformance",
        component: () => import("@/views/Reporting/NetworkPerformance")
      },
      {
        path: "/reporting/lead-delivery",
        name: "leadDeliveryReport",
        component: () => import("@/views/Reporting/LeadDeliveryReport")
      },
      {
        path: "/manage/network/businesses",
        name: "businesses",
        component: () =>
          import("@/views/Management/Network/Businesses/BusinessesOverview")
      },
      {
        path: "/manage/network/advertiser/accounts",
        name: "advertiserAccounts",
        component: () =>
          import(
            "@/views/Management/Network/AdvertiserAccounts/AdvertiserAccountsOverview"
            )
      },
      {
        path: "/manage/network/advertiser/accounts/edit/:advertiserAccountId",
        name: "advertiserAccountEditor",
        component: () =>
          import(
            "@/views/Management/Network/AdvertiserAccounts/AdvertiserAccountEditor"
            )
      },
      {
        path: "/manage/offers",
        name: "offers",
        component: () => import("@/views/Management/Offers/OffersOverview")
      },
      {
        path: "/manage/offers/create/:templateOfferId?",
        name: "offerCreator",
        component: () => import("@/views/Management/Offers/OfferCreator")
      },
      {
        path: "/manage/offers/edit/:offerId",
        name: "offerEditor",
        component: () => import("@/views/Management/Offers/OfferEditor")
      },
      {
        path: "/manage/offers/split-tests",
        name: "offerSplitTests",
        component: () =>
          import("@/views/Management/Offers/SplitTests/OfferSplitTestsOverview")
      },
      {
        path: "/manage/offers/split-tests/edit/:offerId",
        name: "offerSplitTestsEditor",
        component: () =>
          import("@/views/Management/Offers/SplitTests/OfferSplitTestsEditor")
      },
      {
        path: "/manage/offers/split-tests/create",
        name: "offerSplitTestsCreator",
        component: () =>
          import("@/views/Management/Offers/SplitTests/OfferSplitTestsEditor")
      },
      {
        path: "/manage/keywords/exclusions",
        name: "keywordsExclusions",
        component: () =>
          import(
            "@/views/Management/Keywords/Exclusions/KeywordsExclusionsOverview"
          )
      },
      {
        path: "/manage/keywords/exclusions/create",
        name: "keywordExclusionCreator",
        component: () =>
          import(
            "@/views/Management/Keywords/Exclusions/KeywordExclusionCreator"
          )
      },
      {
        path: "/manage/keywords/modifiers",
        name: "keywordModifiers",
        component: () =>
          import(
            "@/views/Management/Keywords/Modifiers/KeywordModifiersOverview"
          )
      },
      {
        path: "/manage/keywords/modifiers/create",
        name: "keywordModifierCreator",
        component: () =>
          import("@/views/Management/Keywords/Modifiers/KeywordModifierCreator")
      },
      {
        path: "/manage/keywords/featured",
        name: "keywordsFeatured",
        component: () =>
          import(
            "@/views/Management/Keywords/Featured/KeywordsFeaturedOverview"
          )
      },
      {
        path: "/manage/keywords/featured/create",
        name: "keywordsFeaturedCreator",
        component: () =>
          import("@/views/Management/Keywords/Featured/KeywordsFeaturedCreator")
      },
      {
        path: "/inventory",
        name: "InventoryOverview",
        component: () => import("@/views/Inventory/InventoryOverview.vue")
      },
      {
        path: "/inventory/products/vehicles",
        name: "InventoryVehiclesOverview",
        component: () =>
          import("@/views/Inventory/InventoryVehiclesOverview.vue")
      },
      {
        path: "/inventory/products/jobs",
        name: "InventoryJobsOverview",
        component: () => import("@/views/Inventory/InventoryJobsOverview.vue")
      },
      {
        path: "/inventory/sources",
        name: "InventorySourcesOverview",
        component: () =>
          import("@/views/Inventory/InventorySourcesOverview.vue")
      },
      {
        path: "/inventory/sources/history/:inventorySourceId",
        name: "InventorySourcesImportHistory",
        component: () =>
          import("@/views/Inventory/InventorySourcesImportHistory.vue")
      },
      {
        path: "/manage/misc/geolocation-log",
        name: "geolocationLookupLog",
        component: () => import("@/views/GeolocationLookupLog")
      },
      {
        path: "/ads/inventory-library",
        name: "VehiclesInventoryLibraryVehicleOverview",
        component: () => import("@/views/Ads/VehiclesInventoryLibraryOverview")
      },
      {
        path: "/ads/inventory-library/selector-tool",
        name: "VehiclesInventoryLibraryVehicleSelector",
        component: () =>
          import("@/views/Ads/VehiclesInventoryLibraryVehicleSelector")
      },
      {
        path: "/inventory/pipeline",
        name: "InventoryPipelineOverview",
        component: () => import("@/views/Inventory/InventoryPipelineOverview")
      }
    ]
  }
];

// TODO: Account for below on the server.
const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name === "externalReporting") {
    next();
  } else {
    if (to.name !== "login" && !store.getters["auth/isAuthenticated"]) {
      next({ name: "login" });
    } else if (
      store.getters["auth/isAuthenticated"] &&
      typeof axios.defaults.headers.common["Authorization"] === "undefined"
    ) {
      // If user is authenticated but our Authorization header is not set, then run the login action before we redirect to next route.
      store
        .dispatch("auth/LOGIN")
        .then(() => {
          if (to.name === "login") {
            next("/");
          } else {
            next();
          }
        })
        .catch(() => {
          next({ name: "login" });
        });
    } else {
      next();
    }
  }
});

export default router;
