/* eslint-disable no-unused-vars */
import axios from "axios";

import { GET_REQUEST, POST_REQUEST, POPULATE_LIST } from "@/store/actions";

import {
  POPULATE_LIST_SUCCESS,
  SET_POPULATING_LIST_VALUE
} from "@/store/mutations";
import mixins from "@/mixins";

const state = {
  list: [],
  populatingList: false
};

const getters = {
  byOffer: state => {
    if (state.list.length === 0) return [];
    let byOfferId = mixins.methods.convertToObjectOfArraysById(
      state.list,
      "offer_id"
    );
    let byOffer = [];
    Object.entries(byOfferId).forEach(([key, value]) => {
      byOffer.push({
        id: key,
        name: value[0]["offer_name"],
        splitTests: value
      });
    });
    return byOffer;
  }
};

const actions = {
  [POPULATE_LIST]: ({ commit, dispatch }) => {
    commit(SET_POPULATING_LIST_VALUE, true);
    return new Promise((resolve, reject) => {
      dispatch(GET_REQUEST, {})
        .then(data => {
          commit(POPULATE_LIST_SUCCESS, data);
          commit(SET_POPULATING_LIST_VALUE, false);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [GET_REQUEST]: ({ commit }, { params = {} }) => {
    return new Promise((resolve, reject) => {
      axios({
        url: "https://api-v2.jumpfeed.com/internal/offers/split-tests",
        params
      })
        .then(resp => {
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  },
  [POST_REQUEST]: (
    { commit, dispatch },
    { endpoint = "update", params = {} }
  ) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          "https://api-v2.jumpfeed.com/internal/offers/split-tests/" + endpoint,
          params
        )
        .then(resp => {
          dispatch(POPULATE_LIST);
          let responseData = resp.data.data;
          resolve(responseData);
        })
        .catch(err => {
          console.log(err);
          reject(err);
        });
    });
  }
};

const mutations = {
  [POPULATE_LIST_SUCCESS]: (state, data) => {
    state.list = data;
  },
  [SET_POPULATING_LIST_VALUE]: (state, value) => {
    state.populatingList = !!value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
